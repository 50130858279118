<template>
  <div>
    <b-row>
      <b-col sm="6"><label>Options</label></b-col>
      <b-col sm="2" class="text-center"><label>Default</label></b-col>
    </b-row>
    <b-row
      v-for="(choice, index) in questionList[questionActive].field_choices"
      :key="index"
      class="mb-3"
    >
      <b-col sm="6" class="d-flex align-items-center">
        <span class="mr-4" style="width: 10px">{{ index + 1 }}</span>
        <InputText
          class="w-100 mr-4 mb-0"
          textFloat=""
          v-model="choice.name"
          :placeholder="choice.name"
          type="text"
        />
        <InputText
          class="w-100 mr-4 mb-0"
          textFloat="Display Title Language"
          v-model="choice.field_language.name"
          :placeholder="choice.field_language.name"
          type="text"
        />
        <b-icon
          size="lg"
          icon="trash-fill"
          class="icon-size pointer"
          @click="questionList[questionActive].field_choices.splice(index, 1)"
        />
      </b-col>
      <b-col sm="2" class="d-flex align-items-center justify-content-center">
        <b-form-checkbox
          :id="`checkbox-question-default-${index}`"
          :name="`checkbox-question-default-${index}`"
          v-model="choice.isDefault"
          :value="1"
          :unchecked-value="0"
          @change="SelectIsDefault(index, $event)"
        ></b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mt-3 d-flex align-items-center">
        <span class="mr-4">{{
          (questionList[questionActive]?.field_choices?.length || 0) + 1
        }}</span
        ><span
          class="text-underline pointer"
          @click="addChoice(questionList[questionActive].field_choices)"
          >Add Option</span
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  name: "FieldChoiceDetail",
  components: {
    InputText,
  },
  props: {
    questionList: {
      required: true,
      type: Array,
    },
    questionActive: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
    };
  },
  methods: {
    addChoice(item) {
      let len = item.length + 1;
      item.push({
        name: "choice " + len,
        sort_order: item.length,
        isDefault: 0,
      });
    },
    async SelectIsDefault(index, val) {
      if (this.questionList[this.questionActive].field_type_id != 2) {
        this.questionList[this.questionActive].field_choices.forEach((c, i) => {
          if (i == index) {
            c.isDefault = val || 0;
          } else {
            c.isDefault = 0;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
</style>
